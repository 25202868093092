@import 'styles/breakpoints.scss'
@import 'styles/variables.sass'

.alert
  display: block
  position: relative

  margin: 2rem 0
  text-wrap: balance
  border: 1px solid $alert-red
  padding: 2rem 2rem 2rem 4rem
  background: $red-gradient
  border-radius: 1rem
  box-shadow: $alert-red-shadow
  &:hover
    box-shadow: $alert-red-shadow, 0px 0px 4px $alert-red

    &:after
      content: ''
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      border-radius: 1rem
      background: $red-gradient
      box-shadow: $alert-red-shadow
  
  .alertTitle
    font-size: 2rem

    margin-bottom: 1rem
    color: $alert-red
    text-shadow: 0px 0px 2px $light-red

  .alertText
    padding-left: 1rem
  .link
    color: $blue
    text-decoration: underline
    font-weight: 700
  p 
   line-height: 1.25rem

.container
  min-height: calc(100vh - 14rem) // 100vh - (4rem header + 10rem footer)
  margin-top: 4rem // 2rem + 4rem header
  padding-top: 2rem

@include media('>phone')
  .container
    margin-top: 3.5rem

@include media('>Ltablet')
  .container
    margin-top: 4rem
    padding-top: 4rem
